//Import de Librairies
import React, { useReducer, useState } from "react"


//Import de composants
import Layout from "../components/decorate/Layout"

//Import de Fichiers
import prix from '../utils/prix.json'
import "../scss/pages/prix.scss"
import Logo from "../images/logo2.png"

/**
 *
 * @param contentQuotation tableau qui contient les éléments choisis pour le devis
 * @param handleSetQuotation méthode du parent permettant d'enlever un élément du devis
 * @returns {JSX.Element} le devis
 * @constructor
 */
function Quotation({contentQuotation, handleSetQuotation}) {

  /**
   * @returns {number}montant total du devis
   */
  const getTotal = function(){
    let amount=0
    contentQuotation.forEach((el) => {
      amount += el.nb * el.price
    })
    return  amount
  }

  /**
   * Je récupère l'index de l'élément à retirer.
   * Puis je fais appel à la méthode du parent pour retirer l'élément du tableau du devis
   * @param e l'évènement
   */
  const handleClick = function(e) {
    e.preventDefault()
    handleSetQuotation("remove",contentQuotation[e.target.dataset.el])
  }

  return (
    <div className="devis">
      <div className="devis__head"><span>Votre devis GetCaaS</span><img src={Logo} alt="" className="logo" /></div>
      <div className="devis__body">
        <div className="devis__body__left"><span>Détails : </span></div>
        <div className="devis__body__right">
          {
            contentQuotation.map((el, index) => {
              return (
                <div className="line-devis">
                  <span>{el.nb} {el.title}</span>
                  <div>
                    <span>{el.nb*el.price} € HT</span>
                    <button style={{marginLeft: 5, fontWeight: "bold"}} className="clickable" data-el={index} aria-label="remove_item" onClick={handleClick}>x</button>
                  </div>
                </div>

              )
            })
          }
        </div>
      </div>
      <div className="devis__foot">
        <span>Montant estimé :</span>
        <span className="amount">{getTotal()} € HT</span>
      </div>
    </div>
  )
}

/**
 *
 * @param element correspond à un produit de la page prix
 * @param handleSetQuotation Méthode du parent permettant d'ajouter un élement au devis
 * @returns {JSX.Element}
 * @constructor
 */
function PriceElement({element, handleSetQuotation}) {
  const {title, RAM, CPU, HDD, Price} = element
  const [qty, setQty] = useState(1)

  /**
   * Renvoie l'objet afin de l'ajouter au tableau du devis
   */
  const pushElement = function() {
    handleSetQuotation("add",{title:title, price:Price, qty: qty})
  }

  return(
    <div className="price-ctn">
      <div className="price-ctn__head">
        <span>{title}</span>
      </div>
      <div className="price-ctn__body">
        <span>{RAM} Go RAM</span>
        <span>{CPU} vCPU</span>
        <span>{HDD} Go d'espace disque</span>
        <span>{Price} € HT/Mois</span>
      </div>
      <div className="price-ctn__btns">
        <div className="price-ctn__btnsAdd">
          <button value="Ajouter" onClick={ pushElement}>Ajouter</button>
        </div>
        <div className="price-ctn__btns__moreOption"><input type="number" value={qty} name="qty" onChange={(e) => setQty(e.target.value)}/></div>
      </div>

    </div>
  )
}



/**
 * Composant principal
 * @returns {JSX.Element}
 * @constructor
 */
export default function Prices(){

  const [tabQuotation, dispatch] = useReducer(reducer,[])
  const [selectedTab, setSelectedTab] = useState("app")

  const tab = prix.fr

  const handleDispatch = function(type, el) {
    dispatch({type: type, el:el})
  }

  return(
    <Layout>
      <div className="page-price">
        <div className="price-main">
          <div className="list-price">
            <div className="list-price__head">Tarifs de nos conteneurs</div>
            <div className="list-price__tab">
              {
                Object.keys(prix.fr).map((el) => <div key={el} className={`clickable ${el === selectedTab && "isActive"}`} onClick={()=>setSelectedTab(el)} aria-hidden="true">{el.toUpperCase()}</div>)
              }
            </div>
            <div className="list-price__body">
              {
                tab[selectedTab].data.map((el, index) => {
                  return <PriceElement element={el}  handleSetQuotation={handleDispatch} key={index}/>
                })
              }
            </div>
          </div>
        </div>
        <div className="horyzontal-bar"></div>
        <Quotation contentQuotation={tabQuotation} handleSetQuotation={handleDispatch}/>
      </div>
    </Layout>

  )
}

/***************************** */
/** HOOKS ET METHODES UTILISEES*/
/***************************** */

/**
 *
 * @param state état du hook
 * @param action type d'action à effectuer
 * @returns {*[]} le tableau qui possède le nouvel état
 */
function reducer(state, action) {
  switch (action.type) {
    case 'add' : return addToQuotation(state,action.el)
    case 'remove' : return removeFromQuotation(state, action.el)
    default:  throw new Error()
  }
}

/**
 *
 * @param tab tableau du devis qui va être traité
 * @param el l'élément à retirer
 * @returns {*[]}
 */
function addToQuotation(tab, el) {
  let item = tab.findIndex((row) => row.title === el.title)

  if(item === -1) {
    return [...tab,{nb: el.qty,...el}]
  }else{
    tab[item].nb += +el.qty
    return [...tab]
  }
}

/**
 *
 * @param tab tableau du devis qui va être traité
 * @param el l'élément à retirer
 * @returns {*[]}
 */
function removeFromQuotation(tab, el) {
  let item = tab.findIndex((row) => row.title === el.title)
  tab[item].nb > 1 ? tab[item].nb -= 1 : tab.splice(item,1)
  return [...tab]
}